import React from "react";
import { useFormik } from "formik";
import axios from "../utils/axios-config";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const Auth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: (values) => {
      console.log(values);
      axios
        .post("/api/user/register", values, { withCredentials: true })
        .then((res) => {
          toast.success(`Successfully registered`);
          navigate("/auth");
        });
    },
  });
  return (
    <div>
      <h1>Register</h1>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group w-25">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            className="form-control"
            id="username"
            name="username"
            {...formik.getFieldProps("username")}
          />
        </div>
        <div className="form-group w-25">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            className="form-control"
            id="password"
            name="password"
            {...formik.getFieldProps("password")}
          />
        </div>
        <button type="submit" className="btn btn-primary mt-2">
          Register
        </button>
      </form>
    </div>
  );
};

export default Auth;
