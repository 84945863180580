import React from "react";
import { useFormik } from "formik";
import axios from "../utils/axios-config";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";

const Auth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [cookies, setCookie, removeCookie] = useCookies(["x-access-token"]);
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: (values) => {
      axios
        .post("/api/user/login", values, { withCredentials: true })
        .then((res) => {
          window.location = "/";
          setCookie(
            "x-access-token",res.data.token
          )
          toast.success(`Login as ${res.data.username}`);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });
  return (
    <div>
      <h1>Login</h1>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group w-25">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            className="form-control"
            id="username"
            name="username"
            {...formik.getFieldProps("username")}
          />
        </div>
        <div className="form-group w-25">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            className="form-control"
            id="password"
            name="password"
            {...formik.getFieldProps("password")}
          />
        </div>
        <button type="submit" className="btn btn-primary mt-2">
          Login
        </button>
      </form>
    </div>
  );
};

export default Auth;
