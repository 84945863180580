import React, { useEffect } from "react";
import axios from "../utils/axios-config";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik, FormikProvider, FieldArray } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Badge } from "react-bootstrap";

const EditFarm = ({ xAccessToken }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const farmid = location.pathname.split("/")[2];
  useEffect(() => {
    loadFarmData();
  }, []);
  const loadFarmData = () => {
    axios
      .get(`/api/farm/id/${farmid}`, {
        headers: {
          "x-access-token": xAccessToken,
        },
      })
      .then((res) => {
        formik.setValues({
          name: res.data.farm.name,
          description: res.data.farm.description
            ? res.data.farm.description
            : "",
          tags: res.data.farm.tags,
        });
      })
      .catch((err) => {
        toast.error(err.response.data.error);
      });
  };
  const submitEditFarm = (values) => {
    console.log(values);
    axios
      .patch(
        `/api/farm/id/${farmid}`,
        {
          name: values.name,
          description: values.description,
          tags: values.tags,
        },
        {
          headers: {
            "x-access-token": xAccessToken,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        navigate("/");
      })
      .catch((err) => {
        toast.error(err.response.data.error);
      });
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      tag: "",
      tags: [],
    },
    onSubmit: submitEditFarm,
    validationSchema: Yup.object({
      name: Yup.string().required("Farm name is required"),
      description: Yup.string(),
    }),
  });
  return (
    <div>
      <h1>Edit Farm</h1>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group w-50">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            {...formik.getFieldProps("name")}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="text-danger">{formik.errors.name}</div>
          ) : null}
        </div>
        <div className="form-group w-50">
          <label htmlFor="description">Description</label>
          <input
            type="text"
            className="form-control"
            id="description"
            name="description"
            {...formik.getFieldProps("description")}
          />
          {formik.touched.description && formik.errors.description ? (
            <div className="text-danger">{formik.errors.description}</div>
          ) : null}
        </div>
        <FormikProvider value={formik}>
          <FieldArray
            name="tags"
            render={(arrayHelper) => (
              <>
                <div className="form-group w-50">
                  <label htmlFor="tags">Tags</label>
                  <input
                    onKeyDown={(e) => {
                      if (e.key === " " && e.target.value.length > 1) {
                        arrayHelper.push(formik.values.tag.trim());
                        formik.setFieldValue("tag", "");
                      }
                    }}
                    type="text"
                    className="form-control"
                    id="tag"
                    name="tag"
                    {...formik.getFieldProps("tag")}
                  />
                </div>
                <div>
                  {formik.values.tags.map((tag, index) => (
                    <>
                      <Badge
                        className="btn me-2 mt-2"
                        key={index}
                        onClick={() => {
                          arrayHelper.remove(index);
                        }}>
                        {tag}
                      </Badge>
                    </>
                  ))}
                </div>
              </>
            )}
          />
        </FormikProvider>
        <button type="submit" className="btn btn-success mt-2">
          Update
        </button>
      </form>
    </div>
  );
};

export default EditFarm;
