import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import axios from "../utils/axios-config";
import { useCookies } from "react-cookie";
import Loading from "./Loading";

const PrivateRoute = ({ authAllow = true, children }) => {
  const [auth, setAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [cookies, setCookie, removeCookie] = useCookies(["x-access-token"]);
  useEffect(() => {
    if (!!cookies["x-access-token"]) {
      axios
        .get("/api/user/isauth", {
          headers: {
            "x-access-token": cookies["x-access-token"],
          },
        })
        .then((res) => {
          setAuth(true);
          setLoading(false);
        })
        .catch((err) => {
          setAuth(false);
          setLoading(false);
        });
    } else {
      setAuth(false);
      setLoading(false);
    }

    return () => {};
  }, []);
  return loading ? (
    <Loading />
  ) : authAllow && auth ? (
    children
  ) : authAllow && !auth ? (
    <Navigate to="/auth" state={{ from: location.pathname }} />
  ) : !authAllow && auth ? (
    <Navigate to="/" state={{ from: location.pathname }} />
  ) : !authAllow && !auth ? (
    children
  ) : null;
};

export default PrivateRoute;
