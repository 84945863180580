import React from "react";
import { Button, Badge } from "react-bootstrap";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../utils/axios-config";

const FarmResult = ({ resultFarm, xAccessToken, fetchFarmData }) => {
  const navigate = useNavigate();
  const deactivateFarm = (farmid) => {
    axios
      .post(
        `/api/farm/deactivate/${farmid}`,
        {},
        {
          headers: {
            "x-access-token": xAccessToken,
          },
        }
      )
      .then((res) => {
        fetchFarmData();
      })
      .catch((err) => {
        toast.error(err.response.data.error);
      });
  };
  const deleteFarm = (farmid) => {
    axios
      .delete(`/api/farm/id/${farmid}`, {
        headers: {
          "x-access-token": xAccessToken,
        },
      })
      .then((res) => {
        toast.success(res.data.message);
        fetchFarmData();
      })
      .catch((err) => {
        toast.error(err.response.data.error);
      });
  };
  return (
    <tbody>
      {resultFarm.map((farm, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td className="col-2">{farm.name}</td>
          <td>{farm.description ? farm.description : ""}</td>
          <td>
            {farm.tags
              ? farm.tags.map((tag, index) => (
                  <Badge key={index} className="me-2 fs-6">
                    {tag}
                  </Badge>
                ))
              : ""}
          </td>
          <td className="col-1">
            {/* {!farm.activated && (
      <span className="badge mx-1 fs-6 bg-success">
        Code: {farm.activationCode}
      </span>
    )} */}
            <span
              className={`badge mx-1 fs-6 ${
                farm.activated
                  ? farm.offline
                    ? "bg-danger"
                    : "bg-success"
                  : "bg-warning text-black"
              }`}>
              {farm.activated
                ? farm.offline
                  ? "Offline"
                  : "Activated"
                : `Activation Code: ${farm.activationCode}`}
            </span>
          </td>
          <td className="col-1">
            {farm.workerCount < 0 ? "N/A" : farm.workerCount}
          </td>
          <td className="col-1">
            {farm.activeWorkerCount < 0 ? "N/A" : farm.activeWorkerCount}
          </td>
          <td className="col-1">
            {farm.avgActive < 0 ? "N/A" : farm.avgActive}
          </td>
          <td className="col-1">
            {farm.lastActivatedAt.length > 0 &&
              farm.lastActivatedAt.map((date, index) => (
                <div key={index}>
                  <Moment format="DD/MM hh:mm:ss">{date}</Moment>
                  <br />
                </div>
              ))}
          </td>
          <td className="col-2">
            <Button
              className="mx-1"
              onClick={() => {
                navigate(`/editfarm/${farm._id}`);
              }}>
              Edit
            </Button>
            {farm.activated ? (
              <Button
                className="mx-1 btn-warning"
                onClick={() => deactivateFarm(farm._id)}>
                Deactivate
              </Button>
            ) : null}
            <Button className="btn-danger" onClick={() => deleteFarm(farm._id)}>
              Delete
            </Button>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default FarmResult;
