import React, { useEffect, useState } from "react";
import axios from "../utils/axios-config";
import { Table } from "react-bootstrap";
import { toast } from "react-toastify";
import FarmResult from "../components/FarmResult";

const Home = ({ xAccessToken }) => {
  const [farm, setFarm] = useState([]);
  const [searchFarm, setSearchFarm] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [isSort, setIsSort] = useState(false);
  const [isSortAscend, setIsSortAscend] = useState(true);
  const [searchContent, setSearchContent] = useState("");
  const [activeWorkerCount, setActiveWorkerCount] = useState(-1);
  const [totalActiveFarm, setTotalActiveFarm] = useState(-1);

  useEffect(() => {
    fetchFarmData();
  }, []);

  useEffect(() => {
    let result = [...farm];
    if (searchContent) {
      setIsSearch(true);
      result = result.filter(
        (item) =>
          item.tags.filter((tag) =>
            tag.toLowerCase().includes(searchContent.toLowerCase())
          ).length > 0
      );
    } else {
      setIsSearch(false);
    }
    if (!isSort) {
      result = result.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));
    } else {
      if (isSortAscend) {
        result = result.sort((a, b) => (a.name > b.name ? 1 : -1));
      } else {
        result = result.sort((a, b) => (a.name < b.name ? 1 : -1));
      }
    }
    setSearchFarm(result);
  }, [searchContent, isSort, isSortAscend]);

  const fetchFarmData = () => {
    axios
      .get("/api/farm/all", {
        headers: {
          "x-access-token": xAccessToken,
        },
      })
      .then((res) => {
        setFarm(res.data.farm);
        setSearchFarm(res.data.farm);
        setActiveWorkerCount(res.data.activeWorkerCount);
        setTotalActiveFarm(res.data.totalActiveFarm);
      })
      .catch((err) => {
        toast.error(err.response.data.error);
      });
  };

  const AscendBtn = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="blue"
      className="bi bi-caret-up"
      viewBox="0 0 16 16"
      style={{ cursor: "pointer" }}
      onClick={() => {
        setIsSortAscend(false);
      }}>
      <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z" />
    </svg>
  );
  const DescendBtn = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="blue"
      className="bi bi-caret-down"
      viewBox="0 0 16 16"
      style={{ cursor: "pointer" }}
      onClick={() => {
        setIsSortAscend(true);
        setIsSort(false);
      }}>
      <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
    </svg>
  );
  const SortBtn = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="grey"
      className="bi bi-caret-up"
      viewBox="0 0 16 16"
      style={{ cursor: "pointer" }}
      onClick={() => {
        setIsSort(true);
        setIsSortAscend(true);
      }}>
      <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z" />
    </svg>
  );

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h2>FMMS Activator</h2>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "22rem",
            }}>
            {activeWorkerCount >= 0 && (
              <h5 style={{ width: "-webkit-fill-available", margin: "0.5rem" }}>
                Active Workers: {activeWorkerCount}
              </h5>
            )}
            {totalActiveFarm >= 0 && (
              <h5 style={{ width: "-webkit-fill-available", margin: "0.5rem" }}>
                Active Farm: {totalActiveFarm}
              </h5>
            )}
          </div>
          <input
            type="text"
            className="form-control d-inline-block"
            style={{
              height: "fit-content",
              width: "fit-content",
              margin: "0.5rem",
            }}
            placeholder="Search tag"
            value={searchContent}
            onChange={(e) => setSearchContent(e.target.value)}
          />
        </div>
      </div>
      <div className="my-2"></div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th style={{ display: "flex", justifyContent: "space-between" }}>
              Name {isSort ? (isSortAscend ? AscendBtn : DescendBtn) : SortBtn}
            </th>
            <th>Description</th>
            <th>Tags</th>
            <th>Status</th>
            <th>Worker Count</th>
            <th>Active Worker Count</th>
            <th>Avg Active Worker Count</th>
            <th>Last Active</th>
            <th>Action</th>
          </tr>
        </thead>
        {isSort || isSearch ? (
          <FarmResult
            resultFarm={searchFarm}
            xAccessToken={xAccessToken}
            fetchFarmData={fetchFarmData}
          />
        ) : (
          <FarmResult
            resultFarm={farm}
            xAccessToken={xAccessToken}
            fetchFarmData={fetchFarmData}
          />
        )}
      </Table>
    </div>
  );
};

export default Home;
