import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import PrivateRoute from "./components/PrivateRoute";
import Home from "./pages/Home";
import Auth from "./pages/Auth";
import NewFarm from "./pages/NewFarm";
import Register from "./pages/Register";
import EditFarm from "./pages/EditFarm";
import { useCookies } from "react-cookie";
import Header from "./components/Header";

function App() {
  const [cookies, setCookie, removeCookie] = useCookies(["x-access-token"]);
  const xAccessToken = cookies["x-access-token"];
  return (
    <div className="App w-100 h-100">
      <div className="m-5">
        <ToastContainer />
        <Router>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <PrivateRoute>
                  <Header />
                  <Home xAccessToken={xAccessToken} />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/admin"
              element={
                <PrivateRoute>
                  <Header />
                  <Home xAccessToken={xAccessToken} />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/newfarm"
              element={
                <PrivateRoute>
                  <Header />
                  <NewFarm xAccessToken={xAccessToken} />
                </PrivateRoute>
              }
            />
            <Route
              path="/editfarm/:id"
              element={
                <PrivateRoute>
                  <Header />
                  <EditFarm xAccessToken={xAccessToken} />
                </PrivateRoute>
              }
            />
            <Route
              path="/auth"
              element={
                <PrivateRoute authAllow={false}>
                  <Auth />
                </PrivateRoute>
              }
            />
            <Route
              path="/register"
              element={
                <PrivateRoute authAllow={false}>
                  <Register />
                </PrivateRoute>
              }
            />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
